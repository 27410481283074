import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import BreadCrumbs from "../components/breadCrumbs"
import Head from '../components/head'
import GetInTouch from "../components/GetInTouch"

const BlogSinglePage = ({ data }) => {
    const blog = data.UI.dsBlogs[0]
    return(
        <Layout>
            <Head SEO_Tags={blog.SeoTags} />
            <BreadCrumbs crumbs={blog.Title}/>
            <section className="BG-F9FAFE work_hero tB-padd-50">
                <div className="container">
                    <div className="row justify-content-center">
                        
                        <div className="col-md-12 col-lg-12 col-sm-12">
                            {blog.Title ? (<h1>{blog.Title}</h1>) : null}
                            {blog.Featured_Image ? (
                                <img src={blog.Featured_Image.url} alt={blog.Featured_Image.alternativeText} />
                            ) : null}   
                            {blog.Description ? (
                                <div dangerouslySetInnerHTML={{ __html: blog.Description }} />
                            ) : null}
                        </div>
                    </div>
                </div>
            </section>
            <section className="sectionCTA">
                <GetInTouch />
            </section>
        </Layout>
    )
}

export default BlogSinglePage

export const query = graphql`
  query Blogs($slug: String!) {
    UI {        
        dsBlogs(where: {Slug: $slug}) {
            Title
            Slug
            Featured_Image {
              url
              alternativeText
            }
            Description
            SeoTags {
              Meta_Title
              Meta_Description
            }
        }
    }
  }
`